div.ui.modal.active.visible div.header,
div.ui.modal.active.visible i.close.icon {
    color: #314456;
    font-size: 18px;
    transition-duration: 0.5s;
}

div.ui.modal.active.visible i.close.icon:hover {
    color: #2185d0;
    transition-duration: 0.2s;
}

div.ui.modal div.content {
    padding: 30px;
    color: #314456;
}
