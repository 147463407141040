div.item.dropdown.language-switcher img.ui.image.flag-image {
    width: 20px;
}

div.item.dropdown.language-switcher div.menu.visible.transition {
    width: max-content;
}

div.item.dropdown.language-switcher div.menu.visible.transition div.item {
    display: flex !important;
}

div.item.dropdown.language-switcher div.menu.visible.transition div.item img.ui.image {
    width: 20px;
}