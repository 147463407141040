div.ui.container.no-role-page-container {
    padding-top: 150px;
}

div.ui.container.no-role-page-container div.content {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #e3e7f1;
    color: #314456;
    font-weight: bold;
    font-size: 28px;
}

div.ui.container.no-role-page-container div.code {
    font-size: 50px;
}

div.ui.container.no-role-page-container div.info-text {
    font-weight: initial;
    font-size: 20px;
}

div.ui.container.no-role-page-container div.go-to-dashboard {
    margin-top: 30px;
}
