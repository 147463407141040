div.ui.grid.report-list-filter-container {
    margin-bottom: 15px;
}

div.ui.grid.report-list-filter-container div.column.sort-container {
    display: flex;
    flex-direction: row;
}

div.ui.grid.report-list-filter-container div.column.sort-container div.ui.container.select{
    width: 100% !important;
    margin: 0 !important;
}