div.ui.container.custom-date-interval-container span {
    margin: 5px;
}

input.custom-date-picker {
    margin: 5px 0;
    border-color: transparent !important;
    transition-duration: 0.5s;
}

input.custom-date-picker:focus {
    border: 1px solid #5c7893 !important;
    transition-duration: 0.2s;
}

div.react-datepicker {
    border: 1px solid #5c7893;
    border-radius: 5px;
    font-size: 13px;
}

div.react-datepicker div.react-datepicker__header {
    background-color: #5c7893;
    border: none;
}

div.react-datepicker__day-name {
    margin-top: 5px;
    padding: 3px;
    width: 2.2rem;
}

div.react-datepicker div.react-datepicker__header div.react-datepicker__current-month,
div.react-datepicker div.react-datepicker__header div.react-datepicker__day-name {
    color: #ffffff;
    font-size: 13px;
}

div.react-datepicker div.react-datepicker__day {
    color: #314456;
    padding: 3px;
    width: 2.2rem;
    transition-duration: 0.5s;
}

div.react-datepicker div.react-datepicker__day:hover {
    color: #ffffff;
    background-color: #2185d0;
    transition-duration: 0.2s;
}

div.react-datepicker div.react-datepicker__day.react-datepicker__day--disabled {
    color: #A1A1A1;
    transition-duration: 0.5s;
}

div.react-datepicker div.react-datepicker__day.react-datepicker__day--disabled:hover {
    background-color: #ffffff;
    transition-duration: 0.2s;
}

div.react-datepicker div.react-datepicker__day.react-datepicker__day--weekend {
    font-weight: bold;
}

div.react-datepicker div.react-datepicker__day.react-datepicker__day--today {
    background-color: #e3e7f1;
    font-weight: bold;
}

div.react-datepicker div.react-datepicker__day.react-datepicker__day--today.react-datepicker__day--disabled:hover {
    background-color: #e3e7f1;
}

div.react-datepicker div.react-datepicker__day.react-datepicker__day--keyboard-selected {
    background-color: #5c7893;
    color: #ffffff;
    font-weight: bold;
}

div.react-datepicker div.react-datepicker__day.react-datepicker__day--keyboard-selected.react-datepicker__day--disabled:hover {
    background-color: #5c7893;
}

div.react-datepicker div.react-datepicker__today-button {
    background-color: #5c7893;
    color: #ffffff;
    border: none;
    transition-duration: 0.5s;
}

div.react-datepicker div.react-datepicker__today-button:hover {
    background-color: #2185d0;
    transition-duration: 0.2s;
}

div.react-datepicker div.react-datepicker__week-number {
    color: #5c7893;
    background-color: #e3e7f1;
}

div.react-datepicker button.react-datepicker__navigation.react-datepicker__navigation--previous {
    border-right-color: #ffffff;
    top: 13px;
    transition-duration: 0.5s;
}

div.react-datepicker button.react-datepicker__navigation.react-datepicker__navigation--previous:hover {
    border-color: #5c7893;
    border-right-color: #2185d0;
    transition-duration: 0.2s;
}

div.react-datepicker button.react-datepicker__navigation.react-datepicker__navigation--next {
    border-left-color: #ffffff;
    top: 13px;
    transition-duration: 0.5s;
}

div.react-datepicker button.react-datepicker__navigation.react-datepicker__navigation--next:hover {
    border-color: #5c7893;
    border-left-color: #2185d0;
    transition-duration: 0.2s;
}

div.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    border-bottom-color: #5c7893;
}

@media (max-width: 623px) {
    div.ui.container.custom-date-interval-container span {
        display: none;
    }

    input.custom-date-picker {
        margin-bottom: 15px;
        border-color: transparent !important;
    }
}