div.ui.accordion.chart-selector-container {
    padding: 0 100px;
    margin-bottom: 15px;
    background-color: #ffffff;
}

div.ui.accordion.chart-selector-container div.title {
    color: #314456;
    font-weight: bold;
    font-size: 18px;
}