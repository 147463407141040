div.ui.container.chart-wrapper {
    margin-bottom: 50px;
    padding: 30px 50px;
    background-color: #e3e7f1;
    color: #314456;
}

div.ui.container.chart-wrapper h1.ui.header {
    color: #314456;
    text-align: center;
}

div.ui.container.chart-wrapper div.ui.grid div.column.chart-navigation-arrow {
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-duration: 0.5s;
}

div.ui.container.chart-wrapper div.ui.grid div.column.chart-navigation-arrow:hover {
    transition-duration: 0.2s;
    color: #2185d0;
}

div.ui.container.chart-wrapper div.ui.grid div.column.chart-navigation-arrow.inactive {
    color: #979797;
}

div.ui.container.chart-wrapper div.ui.grid div.column.chart {
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-content: center;
}