div.ui.breadcrumb a.section,
div.ui.breadcrumb div.section {
    color: #5c7893;
    font-size: 13px;
}

div.ui.breadcrumb a.section:hover {
    color: #2185d0;
}

div.ui.breadcrumb i.icon.divider {
    font-size: 13px;
    margin: 0 5px;
    color: #314456;
}