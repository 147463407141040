div#login-background {
    min-height: 100vh;
    min-width: 100vw;
    /* https://www.pexels.com/photo/background-bloom-blooming-blossom-295771/ --- Free To Use */
    background-image: url('./login-background-spring.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

div#login-container {
    display: flex;
    -moz-display: box;
    -ms-display: flexbox;
    -webkit-display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    padding: 5% 15%;
}

div#login-container div#login-form-wrapper {
    background-color: #ececec;
    border-radius: 8px;
    max-width: 700px;
    display: flex;
    -moz-display: box;
    -ms-display: flexbox;
    -webkit-display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 10px 35px -15px #000000;
}

/* LOGO & TITLE */
div#login-container div#login-form-wrapper div#login-header {
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    background-color: #3b4450;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding: 20px 0;
    font-size: 25px;
}
div#login-container div#login-form-wrapper div#login-header img {
    width: 30px;
    height: 30px;
}

/* ERROR MESSAGES */
div#login-container div#login-form-wrapper div#error-messages {
    text-align: center;
    padding: 10px 0;
    color: #e83d3d;
    font-weight: bold;
}
div#login-container div#login-form-wrapper div#error-messages div {
    padding-bottom: 10px;
}
div#login-container div#login-form-wrapper div#error-messages div:first-child {
    margin-top: 25px;
}
div#login-container div#login-form-wrapper div#error-messages div:last-child {
    padding-bottom: 0;
}

/* FORM */
div#login-container div#login-form-wrapper form {
    padding: 2em;
}
div#login-container div#login-form-wrapper form input:not([type=submit]),
div#login-container div#login-form-wrapper form select {
    color: #384047;
    background-color: #ffffff;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
    border: none;
    border-radius: 4px;
    padding: 1em;
    margin-bottom: 1.2em;
    width: 100%;
    /*height: 40px;*/
    font-size: 12px;
}
div#login-container div#login-form-wrapper form input:focus {
    outline: none;
}

div#login-container div#login-form-wrapper form input.button {
    font-weight: 600;
    text-align: center;
    font-size: 14px;
    color: #ffffff;
    background-color: #3b4450;
    width: 100%;
    border: none;
    border-radius: 4px;
    margin-top: 1em;
    margin-bottom: 1em;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    height: 50px;
}
div#login-container div#login-form-wrapper form input.button[disabled],
div#login-container div#login-form-wrapper form input.button[disabled]:hover {
    background-color: #a4a6a7;
}
div#login-container div#login-form-wrapper form input.button:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    background-color: #444d58;
}
