div.profile-report-list-wrapper {
    margin-bottom: 50px;
    padding: 30px 125px;
    background-color: #e3e7f1;
    color: #314456;
    text-align: center;
}

div.profile-report-list-wrapper table.ui.table {
    border: none;
}

div.profile-report-list-wrapper table.ui.table tr td {
    border: none;
    color: #314456;
}

div.profile-report-list-wrapper table.ui.table tr td.date {
    font-size: 12px;
    font-style: italic;
}

div.profile-report-list-wrapper table.ui.table tr td.title {
    font-weight: bold;
}

div.profile-report-list-wrapper table.ui.table tr td.type,
div.profile-report-list-wrapper table.ui.table tr td.status {
    font-size: 12px;
}