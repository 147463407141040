div.ui.container.chart-card {
    color: #314456;
    background-color: #e3e7f1;
    border-radius: 5px;
    height: 100px;
    min-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition-duration: 0.5s;
    transform: scale(1);
}

div.ui.container.chart-card.active {
    color: #ffffff;
    background-color: #5c7893;
    transition-duration: 0.2s;
}

div.ui.container.chart-card:hover {
    transition-duration: 0.2s;
    transform: scale(1.075);
    box-shadow: 3px 3px 5px 1px #A1A1A1;
    background-color: #4190dc;
    color: #ffffff;
}