div.ui.modal.user-list-to-share div.content {
    color: #314456;
    font-size: 15px;
}

div.ui.modal.user-list-to-share div.content div.ui.list div.item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

div.ui.modal.user-list-to-share div.content div.ui.list div.item span.user-name {
    margin-left: 15px;
}