div.tag-container {
    margin: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 30px;
    color: #ffffff;
    background-color: #5c7893;
    padding-left: 10px;
    border-radius: 3px;
    line-height: 30px;
}

div.tag-container span {
    margin-left: 3px;
    padding: 5px;
    border-radius: 3px;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30px;
    transition-duration: 0.5s;
}

div.tag-container span:hover {
    color: #ffffff;
    background-color: #2185d0;
    transition-duration: 0.2s;
}

div.tag-container.disabled {
    color: #979797;
    background: repeating-linear-gradient(
            45deg,
            #cfcfcf,
            #cfcfcf 10px,
            #e3e7f1 10px,
            #e3e7f1 20px
    );
}

div.tag-container.disabled span:hover {
    color: #979797;
    background: transparent;
}