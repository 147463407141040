div.filter-container {
    padding: 30px;
}

div.filter-container div.grid div.row.custom-interval-wrapper input {
    color: #314456;
    border-color: transparent;
    transition-duration: 0.5s;
}

div.filter-container div.grid div.row.custom-interval-wrapper input:focus {
    border-color: #5c7893;
    transition-duration: 0.2s;
}

div.filter-container div.grid div.row.custom-interval-wrapper div.label {
    color: #ffffff;
    background-color: #5c7893;
}

div.filter-container div.grid div.row.custom-interval-wrapper.inactive {
    display: none;
}