div div.ui.massive.pagination.menu {
    border: none;
    box-shadow: none;
}

div.ui.massive.pagination.menu a.item {
    color: #314456;
    font-weight: bold;
    transition-duration: 0.5s;
}

div.ui.massive.pagination.menu a.item.active {
    color: #ffffff;
    background-color: #5c7893;
    transition-duration: 0.2s;
}

div.ui.massive.pagination.menu a.item:hover {
    color: #ffffff;
    background-color: #2185d0;
    transition-duration: 0.2s;
}