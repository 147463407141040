div.category-box.statistic {
    color: #314456;
    background-color: #e3e7f1;
}

div.category-box.statistic.disabled {
    cursor: initial;
    color: #979797;
    background-color: #cfcfcf;
}
