div.ui.segment.menu-container {
    background-color: #5c7893;
    border-radius: 0;
}

div.ui.segment.menu-container div.menu a,
div.ui.segment.menu-container div.ui.item.dropdown {
    color: #ffffff;
    font-size: 13px;
    font-weight: bold;
    transition-duration: 0.5s;
}

div.ui.segment.menu-container div.menu a:hover {
    background-color: #2185d0;
    color: #ffffff;
    transition-duration: 0.2s;
}

div.ui.segment.menu-container div.ui.item.dropdown:hover {
    background-color: #2185d0;
    transition-duration: 0.2s;
}

div.ui.content-grid div.row div.center.aligned.middle.aligned.nine.wide.column.toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 80px;
}

@media (max-width: 991px) {
    div.ui.container.content-container {
        padding: 0 !important;
    }
}