div.ui.grid.report-condition-builder-container {
    padding: 30px 50px;
    background-color: #e3e7f1;
    color: #314456;
    font-size: 0.85em;
}

div.ui.grid.report-condition-builder-container div.column.saved-conditions-column-with-border {
    border-left: 1px solid rgba(34,36,38,.15);
}

div.ui.modal div.content {
    font-size: 20px;
}