div.ui.container.column-selector-container {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0;
}

div.ui.container.column-selector-container div.title {
    margin-bottom: 15px;
}

div.react-dual-listbox {
    display: flex;
    flex-wrap: wrap;
}

div.react-dual-listbox div.rdl-control-container select.rdl-control {
    border-color: transparent !important;
    border-radius: 5px;
    font-size: 13px;
    color: #314456;
    height: 250px;
    min-width: 200px;
}

div.react-dual-listbox div.rdl-control-container select.rdl-control option {
    padding: 2px;
    transition-duration: 0.3s;
}

div.react-dual-listbox div.rdl-control-container select.rdl-control option:hover {
    color: #ffffff;
    background-color: #2185d0;
    transition-duration: 0.2s;
}

div.react-dual-listbox button.rdl-move,
div.react-dual-listbox button.rdl-move:focus {
    color: #ffffff;
    background-color: #5c7893;
    border-color: transparent;
    border-radius: 5px;
    transition-duration: 0.5s;
}

div.react-dual-listbox button.rdl-move:hover {
    color: #ffffff;
    background-color: #2185d0;
    border-color: transparent;
    transition-duration: 0.2s;
}