div.ui.container.saved-condition-item {
    background-color: #ffffff;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 10px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 253px;
    font-weight: bold;
    transition-duration: 0.5s;
    transform: scale(1);
}

div.ui.container.saved-condition-item.disabled,
div.ui.container.saved-condition-item.disabled:hover {
    color: #979797;
    background-color: #cfcfcf;
    transition-duration: 0.2s;
    transform: none;
    box-shadow: none;
    font-size: inherit;
}

div.ui.container.saved-condition-item:hover {
    transition-duration: 0.2s;
    transform: scale(1.025);
    box-shadow: 1px 1px 3px 1px #A1A1A1;
    background-color: #4190dc;
    color: #ffffff;
    font-size: 12px;
}

div.ui.container.saved-condition-item div.title {
    min-width: 150px;
    width: 100%;
    height: 38px;
    word-wrap: break-word;
    margin: 0;
    display: flex;
    align-items: center;
}

div.ui.container.saved-condition-item div.ui.buttons {
    margin-left: 5px;
}