div.custom-switcher {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 5px;
}

div.custom-switcher div.ui.toggle.checkbox {
    margin-left: 10px;
}