div.ui.checkbox input {
    margin-bottom: 0;
}

div.ui.checkbox label:before {
    border-color: transparent !important;
}

div.ui.checkbox.with-border label:before {
    border-color: #e3e7f1 !important;
}

div.ui.checkbox input:checked~.box:after,
div.ui.checkbox input:checked~label:after {
    color: #314456;
}

div.custom-checkbox-with-label {
    display: flex;
    flex-direction: row;
    align-items: center;
}

div.custom-checkbox-with-label div.ui.checkbox {
    margin-right: 10px;
}