div.back-button {
    cursor: pointer;
    color: #5c7893;
    font-size: 22px;
    margin: 0 20px;
    transition-duration: 0.5s;
}

div.back-button:hover {
    color: #4190dc;
    transition-duration: 0.2s;
}