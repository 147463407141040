div.ui.input input {
    border-color: transparent;
    font-size: 15px;
    transition-duration: 0.5s;
    color: #314456;
    display: flex;
    min-width: 250px;
}

div.ui.input input:focus {
    border: 1px solid #5c7893 !important;
    transition-duration: 0.2s;
    color: #314456;
}

div.ui.input input::placeholder {
    color: #808080;
}