div.ui.modal.save-report-modal div.content div.ui.label {
    color: #314456;
    background-color: #e3e7f1;
    font-size: 13px;
    display: flex;
    align-items: center;
    padding: 0 15px;
}

div.ui.modal.save-report-modal div.content div.ui.input input {
    border-color: #e3e7f1;
    color: #314456;
    font-size: 13px;
    transition-duration: 0.5s;
}

div.ui.modal.save-report-modal div.content div.ui.input input:focus {
    border-color: #5c7893;
    transition-duration: 0.2s;
}

div.ui.modal.save-report-modal div.content span.report-name-length {
    font-size: 13px;
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
}