div.category-box.report {
    color: #ffffff;
    background-color: #5c7893;
}

div.category-box.report div {
    padding: 0 10px;
}

div.category-box.report div.title {
    font-size: 1.1em;
}
