button.ui.button {
    color: #ffffff;
    background-color: #5c7893;
    transition-duration: 0.5s;
}

div.ui.buttons button.ui.button.in-group {
    color: #314456;
    background-color: #e3e7f1;
    transition-duration: 0.5s;
}

button.ui.button:hover,
button.ui.button:focus,
div.ui.buttons button.ui.button.in-group:hover,
div.ui.buttons button.ui.button.in-group:focus {
    color: #ffffff;
    background-color: #2185d0;
    transition-duration: 0.2s;
}